import HomepageFooter from "./HomepageFooter";
import { Link } from "react-router-dom";

export default function Homepage() {
  return (
    <div>
      <div
        role="img"
        aria-label="A photo of Jason in Lofoten, Norway"
        class="fd-image"
      />
      <div className="fd-content">
        <h1 className="fd-title">Jason Brillon</h1>
        <p className="fd-subtitle">
          Senior Software Engineer with a passion for travel, foreign languages,
          and contemporary fiction.
        </p>
        <div className="fd-links">
          <a className="fd-link" href="mailto:JSON.brillon@gmail.com">
            Contact
          </a>
          <Link className="fd-link" to="/books">
            Books
          </Link>
          <a
            className="fd-link"
            href={process.env.PUBLIC_URL + "/Resume.pdf"}
            target="_blank"
          >
            Resume
          </a>
        </div>
      </div>
      <HomepageFooter />
    </div>
  );
}
