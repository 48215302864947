import { useQuery } from "@apollo/client";
import { getBookQuery } from "../queries";

export default function BookOverlay({ id, backgroundColor, onClose }) {
  const { data, loading } = useQuery(getBookQuery, {
    variables: { id },
  });
  if (!loading && data?.book) {
    const { book } = data;
    const otherBooks = book.author.books
      ?.filter((b) => b.name !== book.name)
      .map((b) => b.name);
    return (
      <div className="book-overlay" onClick={() => onClose("")}>
        <div
          className="book-overlay--content"
          style={{ backgroundColor }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className="book-overlay--header">
            <h2 className="book-overlay--title">{book.name}</h2>
            <span>by {book.author.name}</span>
          </div>
          {[...Array(book.rating || 0)].map((r) => "✭")}
          <p>Genre: {book.genre}</p>
          {!!otherBooks.length && (
            <div className="book-overlay--list">
              <span>Other books I've read by {book.author.name} include:</span>
              <ul>
                {otherBooks.map((b) => (
                  <li key={b}>{b}</li>
                ))}
              </ul>
            </div>
          )}
          <div
            role="button"
            aria-label="Close"
            onClick={() => onClose("")}
            className="book-overlay--close"
          >
            ✖
          </div>
        </div>
      </div>
    );
  }
  return null;
}
