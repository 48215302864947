import { gql } from "@apollo/client";

const getBookQuery = gql`
  query getBook($id: ID) {
    book(id: $id) {
      name
      rating
      genre
      author {
        name
        books {
          name
        }
      }
    }
  }
`;

const getBooksQuery = gql`
  query getBooks {
    books {
      name
      id
      highlighted
      reading
    }
  }
`;

export { getBookQuery, getBooksQuery };
