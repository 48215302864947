import { useMemo } from "react";

function randomPastel() {
  return (
    "hsl(" +
    360 * Math.random() +
    "," +
    (25 + 70 * Math.random()) +
    "%," +
    (85 + 10 * Math.random()) +
    "%)"
  );
}

export default function BookSquare({ book, onClick }) {
  const backgroundColor = useMemo(() => randomPastel(), [randomPastel]);
  return (
    <div
      className="book-square"
      role="button"
      onClick={() => onClick(book.id, backgroundColor)}
      style={{ backgroundColor }}
    >
      <div className="book-square--content">
        <span className="book-square--title">{book.name}</span>
        {book.reading && (
          <i className="book-square--callout">reading in progress</i>
        )}
        {!book.reading && book.highlighted && (
          <i className="book-square--callout">thoroughly enjoyed</i>
        )}
      </div>
      <div className="book-square--overlay" />
    </div>
  );
}
