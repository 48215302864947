import { useState } from "react";
import { ReactComponent as LinkedIn } from "../linkedin.svg";
import { ReactComponent as Facebook } from "../facebook.svg";
import { ReactComponent as Github } from "../github.svg";

export default function HomepageFooter() {
  const [activeFooterIcon, setActiveFooterIcon] = useState("");
  function renderFooterIcon(website) {
    const isActive = activeFooterIcon === website;
    return (
      <a
        href={getURL(website)}
        target="_blank"
        className={`fd-footer--icon ${
          isActive ? "fd-footer--active-icon" : ""
        }`}
        aria-label={`Find me on ${website}`}
        onMouseEnter={() => setActiveFooterIcon(website)}
        onMouseLeave={() => setActiveFooterIcon("")}
      >
        {getSVG(website)}
      </a>
    );
  }

  return (
    <footer
      className={`fd-footer ${activeFooterIcon ? "fd-footer--active" : ""}`}
    >
      <div className="fd-footer--icons">
        {renderFooterIcon("linkedin")}
        {renderFooterIcon("facebook")}
        {renderFooterIcon("github")}
      </div>
    </footer>
  );
}

function getSVG(website) {
  switch (website) {
    case "github":
      return <Github />;
    case "facebook":
      return <Facebook />;
    case "linkedin":
      return <LinkedIn />;
    default:
      return null;
  }
}

function getURL(website) {
  switch (website) {
    case "github":
      return "https://github.com/JSONbrillon";
    case "facebook":
      return "https://www.facebook.com/brillon.jason/";
    case "linkedin":
      return "https://www.linkedin.com/in/jason-brillon";
    default:
      return null;
  }
}
