import ReadingList from "./components/ReadingList";
import Homepage from "./components/Homepage";

import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const client = new ApolloClient({
  uri: "/graphql",
  cache: new InMemoryCache(),
});

function App() {
  return (
    <Router>
      <ApolloProvider client={client}>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Lato:300,400,700"
            rel="stylesheet"
          ></link>
          <title>Jason Brillon</title>
        </Helmet>
        <Switch>
          <Route exact path="/books" component={ReadingList} />
          <Route path="/" component={Homepage} />
        </Switch>
      </ApolloProvider>
    </Router>
  );
}

export default App;
