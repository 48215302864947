import { useQuery } from "@apollo/client";
import { useEffect, useState, useCallback } from "react";
import BookSquare from "./BookSquare";
import BookOverlay from "./BookOverlay";
import { getBooksQuery } from "../queries";

export default function ReadingList() {
  const { data, loading } = useQuery(getBooksQuery);
  const { books = [] } = data || {};
  const [activeBookId, setActiveBookId] = useState("");
  const [overlayColor, setOverlayColor] = useState("");
  useEffect(() => {
    const overflowStyle = activeBookId ? "hidden" : "unset";
    document.getElementsByTagName("html")[0].style.overflow = overflowStyle;
    document.body.style.overflow = overflowStyle;
  }, [activeBookId]);
  const onSetActiveBookId = useCallback(
    (id, color) => {
      setActiveBookId(id);
      setOverlayColor(color);
    },
    [setActiveBookId, setOverlayColor]
  );

  if (loading || !books.length) {
    return (
      <div className="book-grid--loading">
        <div className="book-grid--loading-text">
          {loading
            ? "Loading..."
            : "The library is down for maintenance. Try again later!"}
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="book-grid">
        {[...books].reverse().map((book) => (
          <BookSquare book={book} key={book.id} onClick={onSetActiveBookId} />
        ))}
        {activeBookId && (
          <BookOverlay
            id={activeBookId}
            backgroundColor={overlayColor}
            onClose={setActiveBookId}
          />
        )}
      </div>
    </div>
  );
}
